<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление</div>
                <div class="page__desc">Введите данные нового склада</div>
            </div>
        </div>

        <div class="page__content">
            <form class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="address">Адрес</label>
                <div class="control-group">
                    <input
                            v-model="address"
                            id="address"
                            type="text"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="lat">Координаты</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="lat"
                                    id="lat"
                                    class="form-control"
                                    placeholder="42.561408"
                                    required>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="lng"
                                    id="lng"
                                    class="form-control"
                                    placeholder="27.522344"
                                    required>
                        </div>
                    </div>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreStoresCreate',
        data() {
            return {
                address: null,
                lat: null,
                lng: null
            }
        },
        methods: {
            ...mapActions([
                'storeStoresCreate',
            ]),
            async create() {
                await this.storeStoresCreate({
                    address: this.address,
                    lat: this.lat,
                    lng: this.lng
                })
            }
        }
    }
</script>